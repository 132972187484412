export enum Routes {
  Startseite = "/",
  Dienstleistungen = "/dienstleistung",
  Bueroreinigung = "/dienstleistung/bueroreinigung",
  Praxisreinigung = "/dienstleistung/praxisreinigung",
  Treppenhausreinigung = "/dienstleistung/treppenhausreinigung",
  Kindergartenreinigung = "/dienstleistung/kindergartenreinigung",
  Fensterreinigung = "/dienstleistung/fensterreinigung",
  Grundreinigung = "/dienstleistung/grundreinigung",
  Solaranlagenreinigung = "/dienstleistung/solaranlagenreinigung",
  Baureinigung = "/dienstleistung/baureinigung",
  Gastronomiereinigung = "/dienstleistung/gastronomiereinigung",
  Kontakt = "/kontakt",
  UeberUns = "/ueberuns",
  Impressum = "/impressum",
}
